import Image from 'next/image';
import { createComponent, If, IntrinsicProps } from 'react-commons';
import style from './index.module.scss';
import { classicOnlyComponents, classicOnlyRoutes, modernOnlyComponents, modernOnlyRoutes, useThemeStore } from '@/lib/stores/theme';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import Cookies from 'js-cookie';

interface BackgroundImageProps extends IntrinsicProps {
  componentName?: string
}

export default createComponent<BackgroundImageProps>('BackgroundImage', { style }, function BackgroundImage ({ className }, props) {
  const [ authState ] = useAuthStore();
  const [ themeState ] = useThemeStore();
  const router = useRouter();
  const [ visible, setVisible ] = useState(
    !router.asPath.includes('/account/oauth/redirect?returnTo=') && 
    themeState.layout === 'classic'
  );

  useEffect(() => {
    const theme = themeState.theme;
    const layout = authState.user?.layoutPreference || Cookies.get('sw_result_layout') || 'modern';
    
    if (router.asPath.includes('/account/oauth/redirect?returnTo=')) {
      setVisible(false);
    } else if (modernOnlyRoutes.includes(router.pathname) || modernOnlyComponents.includes(props.componentName)) {
      setVisible(false);
    } else if (classicOnlyRoutes.includes(router.pathname) || classicOnlyComponents.includes(props.componentName)) {
      setVisible(true);
    } else if (
      layout === 'classic' ||
      theme === 'default' ||
      theme === 'contrast'
    ) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [ router.pathname, themeState, authState, props.componentName ]);
  
  return (
    <div className={className} aria-hidden='true'>
      {
        If(visible, () => (
          <Image 
            alt='Background image'
            src={'/images/background.jpg'}
            layout='fill'
            objectFit='cover'
            objectPosition='center'
            aria-hidden
          />
        )).EndIf()
      }
    </div>
  );
});
